import { debouncedRef } from '@vueuse/core'
import type { ComputedRef, Ref } from '#imports'
import { computed } from '#imports'

export default function <Type>(
  items: ComputedRef<Type[]>,
  searchText: Ref<string>,
  searchProps: (keyof Type) | (keyof Type)[], 
): ComputedRef<Type[]> {
  return computed(() => {
    if (!items?.value?.length) {
      return []
    }

    if (!searchText.value) return items.value

    const searchQuery = debouncedRef(searchText, 350)
    const lowercaseQuery = searchQuery.value.toLowerCase()
    const parts = lowercaseQuery.split(' ')

    const propsArray = Array.isArray(searchProps) ? searchProps : [searchProps]

    return items.value.filter((item) => {
      return propsArray.some((prop) => {
        const value = item[prop]
        if (typeof value !== 'string') {
          console.warn(
            `searchProp needs to reference a string on the item you're searching`,
          )
          console.warn(`found: ${value} for ${JSON.stringify(item)}`)
          return false
        }

        const lowercaseValue = value.toLowerCase()
        const matches = parts.filter((word) => lowercaseValue.includes(word))

        return parts.length === matches.length
      })
    })
  })
}
